<template>
    <div class="tools-wrapper wrapper d-flex vh-100">
        <!-- <SidePanel /> -->
        <NewSidePanel :isHomePage="isHomePage"/>
        <MainModal />
        <div class="body">
            <NewBreadcrumb v-if="!isHomePage" />
            <!-- <CContainer lg> -->
            <router-view />
            <!-- </CContainer> -->
        </div>
    </div>
    <Loading />
</template>
<script>
import { CContainer } from '@coreui/vue'
// import SidePanel from '@/components/SidePanel.vue'
import NewSidePanel from '@/components/NewSidePanel.vue'
import NewBreadcrumb from '@/components/NewBreadcrumb.vue'
import NewHeader from '@/components/NewHeader.vue'
import MainModal from '@/components/modals/index.vue'
// import AppSidebar from '@/components/AppSidebar.vue'
import Loading from '@/components/ui/Loading.vue'

export default {
    name: 'DynamicDownloadLinkLayout',
    components: {
        // SidePanel,
        NewSidePanel,
        NewHeader,
        NewBreadcrumb,
        MainModal,
        // AppSidebar,
        CContainer,
        Loading
    },
    props: {
        isHomePage: {
            type: Boolean,
            required: false
        }
    }
}
</script>
