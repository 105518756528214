import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useCommonStore } from './common'
import { postSignin } from '@/api/user'

export const useUserStore = defineStore('user', () => {
    const commonStore = useCommonStore()

    // state
    const isLogin = ref(false)
    const userId = ref(null)
    const role = ref(null)
    const permissionList = ref([])

    // actions
    function logout() {
        isLogin.value = false
        localStorage.removeItem('user-token')
        // localStorage.removeItem('user-id')
        commonStore.createToast('Logout', 'Successfully Logout')
        this.$router.push({ name: 'Login' })
    }
    function setUserId(id) {
        userId.value = id
    }
    function authLogin(data) {
        if (data.code === 0) {
            this.$router.push({ name: 'WelcomeChild' })
            isLogin.value = true
            //set user permission after login
            role.value = data.user.role
            permissionList.value = data.user.userPermissionList
            commonStore.createToast('Login Success', 'You are successfully login')
            return
        }
    }

    const setAuth = ({ userRole, userPermissionList }) => {
        isLogin.value = true
        role.value = userRole
        permissionList.value = userPermissionList
    }

    return {
        isLogin,
        logout,
        setUserId,
        authLogin,
        setAuth,
        role,
        permissionList,
    }
})
