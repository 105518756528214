<template>
    <div class="fixed left-0 top-0 z-10 h-full w-full cursor-progress bg-gray-600 opacity-60" v-if="loading">
        <CSpinner
            component="span"
            aria-hidden="true"
            color="info"
            class="absolute left-[50%] top-[50%] h-10 w-10"
        />
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useCommonStore } from '@/stores/common'

const commonStore = useCommonStore()
const loading = computed(() => commonStore.loading)
</script>